import { a as setMode } from './index-608d5bff.js';

const Modes = ['bs4', 'bs5'];

setMode((elm) => {
  let mode;
  if (window.PF &&
    window.PF &&
    window.PF.config.mode) {
    mode = window.PF.config.mode;
  }
  if (elm.getAttribute('mode')) {
    mode = elm.getAttribute('mode');
  }
  if (!mode) {
    mode = 'bs5';
  }
  if (!Modes.includes(mode)) {
    throw new Error('Not supported mode');
  }
  elm.setAttribute('mode', mode);
  return mode;
});
